
import http from '@/utils/http'
import { Modal } from 'ant-design-vue'
import { defineComponent, onMounted, ref } from 'vue'
import { format_date } from '../../../utils/index'

const columns = [
  {
    title: '邀请码',
    dataIndex: 'Code',
    key: 'app_key',
    align: 'center'
  },
  {
    title: '状态',
    dataIndex: 'Codestatus',
    slots: { customRender: 'Codestatus' },
    key: 'app_key',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'Createtime',
    key: 'status',
    slots: { customRender: 'Createtime' },
    align: 'center'
  },
  {
    title: '过期时间',
    dataIndex: 'Expirestime',
    key: 'status',
    slots: { customRender: 'Expirestime' },
    align: 'center'
  },
  {
    title: '管理员类型',
    dataIndex: 'Handlertype',
    key: 'expired',
    slots: { customRender: 'Handlertype' },
    align: 'center'
  },
  {
    title: '操作者',
    dataIndex: 'Handler',
    key: 'expired',
    slots: { customRender: 'expired' },
    align: 'center'
  }
]

export default defineComponent({
  name: 'InvitaionCode',
  setup () {
    const dateValue = ref('a')
    const total = ref(0)
    const currentPage = ref(1)
    const dataSource = ref([])
    const iconLoading = ref(false)
    const form = ref({ app_key: '' })

    const paginationChange = (page: any) => {
      currentPage.value = page
      getList()
    }

    const getList = () => {
      http(`/tokenapi/invitaion_code_list?username=${sessionStorage.getItem('username')}&page=${currentPage.value}`, {
        method: 'get',
         headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          dataSource.value = res.data.list
          total.value = res.data.total
        }
      })
    }

    onMounted(() => {
      getList()
    })

    // 新增邀请码
    const add = () => {
      http(`/tokenapi/invitation_code?operator=${sessionStorage.getItem('username')}`, {
        method: 'get',
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          Modal.confirm({
            title: `邀请码: ${res.data}`,
            cancelText: '关闭',
            okText: '复制邀请码',
            onOk() {
              const input = document.createElement('textarea')
              input.innerHTML = res.data
              document.body.appendChild(input)
              input.select()
              document.execCommand('copy')
              document.body.removeChild(input)
              getList()
            },
            onCancel() {
              getList()
            },
          })
        }
      })
    }

    return {
      columns,
      total,
      currentPage,
      paginationChange,
      dataSource,
      iconLoading,
      add,
      form,
      format_date
    }
  }
})
